import { ChemicalFieldKey } from '../helpers/chemical-utils';

interface SampleFields {
  [ChemicalFieldKey: string]: string;
}

interface ChemicalFields {
  [key: string]: string;
}

type Design = {
  analyte_title: string;
  unit_title: string;
  sample_fields?: SampleFields;
  chemical_fields?: ChemicalFields;
  merge_footer_scenarios?: boolean;
  footer_colspan: number;
  custom_group_span?: boolean;
  use_reference_column?: boolean;
  group_footer_criteria?: boolean;
  disable_scenario_styling?: boolean;
  hide_group_units?: boolean;
  force_orientation?: string;
  group_location_codes?: boolean;
  getDynamicStyling?: Function;
  display_scenario_on_top?: boolean;
  display_on_top_gap?: number;
  duplicate_rpd_title?: string;
  triplicate_rpd_title?: string;
  hide_depth_in_table?: boolean;
  show_depth_in_heading?: boolean;
  display_header_under_subheading?: boolean;
  hide_chemical_row_titles?: boolean;
};

type Config = {
  [key: string]: Design;
};

export enum Template {
  DEFAULT_TEMPLATE = 'default-styling',
  MOMENTUM_TEMPLATE = 'momentum-styling',
  ADE_TEMPLATE = 'ade-styling',
  EPRISK_TEMPLATE = 'eprisk-styling',
  GHD_TEMPLATE = 'ghd-styling',
  FYFE_TEMPLATE = 'fyfe-styling',
  LEGACY_ENVIRO_TEMPLATE = 'legacy-styling',
  RANGE_ENVIRO_TEMPLATE = 'range-enviro-styling',
  RANGE_ENVIRO_BLUE_TEMPLATE = 'range-enviro-blue-styling',
  SLR_TEMPLATE = 'slr-styling',
  SLR_BULK_TEMPLATE = 'slr-bulk-styling',
  GROUNDED_ENG_TEMPLATE = 'grounded-eng-styling',
  AGON_DARWIN_STYLING = 'agon-darwin-styling',
  AGON_SA_STYLING = 'agon-sa-styling',
  SENVERSA_STYLING = 'senversa-styling',
  HELIA_STYLING = 'helia-styling',
  ARC_STYLING = 'arc-styling',
}

export const checkIsEsdatStylingTemplate = (tableStyleKey: Template) => {
  const companies: Template[] = [
    Template.EPRISK_TEMPLATE,
    Template.ADE_TEMPLATE,
    Template.GHD_TEMPLATE,
    Template.FYFE_TEMPLATE,
    Template.LEGACY_ENVIRO_TEMPLATE,
    Template.RANGE_ENVIRO_TEMPLATE,
    Template.AGON_SA_STYLING,
    Template.AGON_DARWIN_STYLING,
    Template.HELIA_STYLING,
    Template.ARC_STYLING,
  ];

  return companies.includes(tableStyleKey);
};

export const DESIGNS: Config = {
  [Template.DEFAULT_TEMPLATE]: {
    analyte_title: 'Analyte',
    unit_title: 'Units',
    footer_colspan: 1,
  },
  [Template.GROUNDED_ENG_TEMPLATE]: {
    analyte_title: 'Sample ID',
    unit_title: 'Units',
    footer_colspan: 1,
    force_orientation: 'vertical',
    display_scenario_on_top: true,
    disable_scenario_styling: true,
  },
  [Template.MOMENTUM_TEMPLATE]: {
    analyte_title: 'Analyte',
    unit_title: 'Sample Name',
    chemical_fields: {
      [ChemicalFieldKey.UNIT_FIELD]: 'Sample Name',
    },
    merge_footer_scenarios: true,
    footer_colspan: 5,
    custom_group_span: true,
    use_reference_column: true,
    group_footer_criteria: true,
    disable_scenario_styling: true,
    hide_group_units: true,
    force_orientation: 'vertical',
    getDynamicStyling: (sampleColumn: number, chemicalColumn: number) => {
      return `
        .momentum-styling tr.heading-row th:nth-child(${chemicalColumn}),
        .momentum-styling tr.heading-sub-row th.main-title {
          border-right: 2px solid #000000;
        }
        
        .momentum-styling tr.chemical-row td:nth-child(${chemicalColumn}),
        .momentum-styling tr.chemical-row th:nth-child(${chemicalColumn}) {
          border-right: 2px solid #000000;
        }
      `;
    },
  },
  [Template.ADE_TEMPLATE]: {
    analyte_title: 'Field ID',
    unit_title: 'Units',
    footer_colspan: 1,
    force_orientation: 'horizontal',
    getDynamicStyling: (sampleColumn: number) => {
      const headColumn = sampleColumn + 1;

      return `
        .ade-styling thead tr.heading-row th:nth-child(n + ${headColumn}) {
          writing-mode: vertical-rl;
          transform: rotate(180deg);
          overflow: hidden;
          height: 225px;
          vertical-align: sub;
        }

        .ade-styling thead .heading-sub-row th:nth-child(-n + ${sampleColumn}),
        .ade-styling thead .heading-row th:nth-child(-n + ${sampleColumn}) {
          border: none;
          width: 170px !important;
        }

        .ade-styling thead .heading-sub-row th.chemical-group-header:nth-child(n + ${headColumn}) {
          border-top: 2px double #000000;
          border-bottom: 2px double #000000;
        }

        .ade-styling.exporting tr.heading-row th:nth-child(n + ${headColumn}) {
          height: unset;
        }

        .ade-styling td:nth-child(${headColumn}),
        .ade-styling th:nth-child(${headColumn}) {
          border-left: 2px double #000000 !important;
        }
      `;
    },
    display_scenario_on_top: true,
    display_on_top_gap: 1,
    hide_chemical_row_titles: true,
  },
  [Template.EPRISK_TEMPLATE]: {
    analyte_title: 'Field ID',
    unit_title: 'Units',
    footer_colspan: 1,
    force_orientation: 'horizontal',
    getDynamicStyling: (sampleColumn: number) => {
      const headColumn = sampleColumn + 1;

      return `
        .eprisk-styling thead tr.heading-row th:nth-child(n + ${headColumn}) {
          writing-mode: vertical-rl;
          transform: rotate(180deg);
          overflow: hidden;
          height: 225px;
          vertical-align: sub;
        }

        .eprisk-styling thead .heading-sub-row th:nth-child(-n + ${sampleColumn}),
        .eprisk-styling thead .heading-row th:nth-child(-n + ${sampleColumn}) {
          border: none;
          width: 170px !important;
        }

        .eprisk-styling thead .heading-sub-row th.chemical-group-header:nth-child(n + ${headColumn}) {
          border-top: 1px solid #000000;
          border-bottom: 1px solid #000000;
        }

        .eprisk-styling.exporting tr.heading-row th:nth-child(n + ${headColumn}) {
          height: unset;
        }

        .eprisk-styling td:nth-child(${headColumn}),
        .eprisk-styling th:nth-child(${headColumn}) {
          border-left: 1px solid #000000 !important;
        }
      `;
    },
    display_scenario_on_top: true,
    display_on_top_gap: 1,
    hide_chemical_row_titles: true,
  },
  [Template.GHD_TEMPLATE]: {
    analyte_title: 'Location Code',
    unit_title: 'Units',
    footer_colspan: 1,
    force_orientation: 'horizontal',
    group_location_codes: true,
    getDynamicStyling: (sampleColumn: number) => {
      const headColumn = sampleColumn + 1;

      return `
        .ghd-styling thead tr.heading-row th:nth-child(n + ${headColumn}) {
          writing-mode: vertical-rl;
          transform: rotate(180deg);
          overflow: hidden;
          height: 225px;
          vertical-align: sub;
        }

        .ghd-styling thead .heading-sub-row th:nth-child(-n + ${sampleColumn}),
        .ghd-styling thead .heading-row th:nth-child(-n + ${sampleColumn}) {
          border: none;
          width: 170px !important;
        }

        .ghd-styling thead .heading-sub-row th.chemical-group-header:nth-child(n + ${headColumn}) {
          border-top: 1px solid #000000;
          border-bottom: 1px solid #000000;
        }

        .ghd-styling.exporting tr.heading-row th:nth-child(n + ${headColumn}) {
          height: unset;
        }

        .ghd-styling tr.hidden-first-cell td:first-child {
          border-left: 1px solid #000000 !important;
        }

        .ghd-styling tr.has-first-cell td:nth-child(${headColumn}),
        .ghd-styling tr.hidden-first-cell td:nth-child(${headColumn - 1}),
        .ghd-styling th:nth-child(${headColumn}) {
          border-left: 1px solid #000000 !important;
        }
      `;
    },
    display_scenario_on_top: true,
    display_on_top_gap: 1,
    hide_chemical_row_titles: true,
  },
  [Template.FYFE_TEMPLATE]: {
    analyte_title: 'Sample No.',
    unit_title: 'Units',
    footer_colspan: 1,
    force_orientation: 'horizontal',
    getDynamicStyling: (sampleColumn: number, chemicalColumn: number) => {
      const headColumn = sampleColumn + 1;

      return `
        .fyfe-styling thead tr.heading-row th:nth-child(n + ${headColumn}) {
          writing-mode: vertical-rl;
          transform: rotate(180deg);
          overflow: hidden;
          height: 225px;
          vertical-align: sub;
        }

        .fyfe-styling thead .heading-sub-row:not(.has-full-colspan) th:nth-child(-n + ${sampleColumn}),
        .fyfe-styling thead .heading-row th:nth-child(-n + ${sampleColumn}) {
          border: none;
          width: 170px !important;
        }

        .fyfe-styling thead .heading-sub-row th.chemical-group-header:nth-child(n + ${headColumn}) {
          border-top: 2px solid #000000;
          border-bottom: 2px solid #000000;
        }

        .fyfe-styling.exporting tr.heading-row th:nth-child(n + ${headColumn}) {
          height: unset;
        }

        .fyfe-styling td:nth-child(${headColumn}),
        .fyfe-styling th:nth-child(${headColumn}) {
          border-left: 2px solid #000000 !important;
        }

        .fyfe-styling tr.has-full-colspan th:nth-child(${headColumn}) {
          border-left: none !important;
        }

        .fyfe-styling tr.has-full-colspan .stick-column-left {
          border-right: 2px solid #000000 !important;
          border-left: none !important;
          border-top: none !important;
          border-bottom: none !important;
        }

        .fyfe-styling tr.rpd_column th {
          border-bottom: 2px solid #000000 !important;
        }
      `;
    },
    display_scenario_on_top: true,
    display_on_top_gap: 2,
    hide_chemical_row_titles: true,
  },
  [Template.LEGACY_ENVIRO_TEMPLATE]: {
    analyte_title: 'Sample Code',
    unit_title: 'Units',
    footer_colspan: 1,
    force_orientation: 'horizontal',
    getDynamicStyling: (sampleColumn: number) => {
      const headColumn = sampleColumn + 1;

      return `
        .legacy-styling thead tr.heading-row th:nth-child(n + ${headColumn}) {
          writing-mode: vertical-rl;
          transform: rotate(180deg);
          overflow: hidden;
          height: 225px;
          vertical-align: sub;
        }

        .legacy-styling thead .heading-sub-row:not(.eql_result_column) th:nth-child(-n + ${sampleColumn}),
        .legacy-styling thead .heading-row th:nth-child(-n + ${sampleColumn}) {
          border: none;
          width: 170px !important;
        }

        .legacy-styling thead .eql_result_column th:nth-child(-n + ${sampleColumn}) {
          border-top: 1px solid #000000 !important;
        }

        .legacy-styling thead .heading-sub-row th.chemical-group-header:nth-child(n + ${headColumn}) {
          border-top: 1px solid #000000;
          border-bottom: 1px solid #000000;
        }

        .legacy-styling.exporting tr.heading-row th:nth-child(n + ${headColumn}) {
          height: unset;
        }

        .legacy-styling td:nth-child(${headColumn}),
        .legacy-styling th:nth-child(${headColumn}) {
          border-left: 1px solid #000000 !important;
        }
      `;
    },
    display_scenario_on_top: true,
    display_on_top_gap: 1,
    hide_chemical_row_titles: true,
  },
  [Template.RANGE_ENVIRO_TEMPLATE]: {
    analyte_title: 'Sample',
    unit_title: 'Units',
    chemical_fields: {
      [ChemicalFieldKey.EQL_FIELD]: 'Limit Of Reporting',
    },
    footer_colspan: 1,
    force_orientation: 'horizontal',
    display_scenario_on_top: true,
    display_on_top_gap: 1,
    getDynamicStyling: (sampleColumn: number) => {
      const headColumn = sampleColumn + 1;

      return `
        .range-enviro-styling thead tr.heading-row th:nth-child(n + ${headColumn}) {
          writing-mode: vertical-rl;
          transform: rotate(180deg);
          overflow: hidden;
          height: 225px;
          vertical-align: sub;
        }

        .range-enviro-styling thead .heading-sub-row:not(.eql_result_column) th:nth-child(-n + ${sampleColumn}),
        .range-enviro-styling thead .heading-row th:nth-child(-n + ${sampleColumn}) {
          border: none;
          width: 170px !important;
        }

        .range-enviro-styling thead .eql_result_column th:nth-child(-n + ${sampleColumn}) {
          border-top: 1px solid #000000 !important;
        }

        .range-enviro-styling thead .heading-sub-row th.chemical-group-header:nth-child(n + ${headColumn}) {
          border-top: 1px solid #000000;
          border-bottom: 1px solid #000000;
        }

        .range-enviro-styling.exporting tr.heading-row th:nth-child(n + ${headColumn}) {
          height: unset;
        }

        .range-enviro-styling td:nth-child(${headColumn}),
        .range-enviro-styling th:nth-child(${headColumn}) {
          border-left: 1px solid #000000 !important;
        }
      `;
    },
    hide_chemical_row_titles: true,
  },
  [Template.RANGE_ENVIRO_BLUE_TEMPLATE]: {
    analyte_title: 'Sample',
    unit_title: 'Units',
    chemical_fields: {
      [ChemicalFieldKey.EQL_FIELD]: 'Limit Of Reporting',
    },
    footer_colspan: 1,
    force_orientation: 'horizontal',
    display_scenario_on_top: true,
    display_on_top_gap: 1,
    duplicate_rpd_title:
      'Relative Percent Difference (% RPD) (blind duplicate)',
    triplicate_rpd_title: 'Relative Percent Difference (% RPD) (split)',
  },
  [Template.SLR_TEMPLATE]: {
    analyte_title: 'Location / Sample Name',
    unit_title: 'Units',
    footer_colspan: 1,
    force_orientation: 'vertical',
    getDynamicStyling: (sampleColumn: number, chemicalColumn: number) => {
      let styleString = '';

      if (chemicalColumn === 1) {
        styleString += `.slr-styling .stick-column-left {
          border-right: 2px solid #000000;
        }`;
      }

      return styleString;
    },
  },
  [Template.AGON_DARWIN_STYLING]: {
    analyte_title: 'Sample',
    unit_title: 'Units',
    chemical_fields: {},
    footer_colspan: 1,
    force_orientation: 'horizontal',
    display_scenario_on_top: true,
    display_on_top_gap: 1,
    getDynamicStyling: (sampleColumn: number) => {
      const headColumn = sampleColumn + 1;

      return `
        .agon-darwin-styling thead tr.heading-row th:nth-child(n + ${headColumn}) {
          writing-mode: vertical-rl;
          transform: rotate(180deg);
          overflow: hidden;
          height: 225px;
          vertical-align: sub;
        }

        .agon-darwin-styling .heading-sub-row:not(.eql_result_column) th:nth-child(-n + ${sampleColumn}),
        .agon-darwin-styling thead .heading-row th:nth-child(-n + ${sampleColumn}) {
          border: none;
          width: 170px !important;
        }

        .agon-darwin-styling thead .eql_result_column th:nth-child(-n + ${sampleColumn}) {
          border-top: 1px solid #000000 !important;
        }

        .agon-darwin-styling thead .heading-sub-row th.chemical-group-header:nth-child(n + ${headColumn}) {
          border-top: 1px solid #000000;
          border-bottom: 1px solid #000000;
        }

        .agon-darwin-styling.exporting tr.heading-row th:nth-child(n + ${headColumn}) {
          height: unset;
        }

        .agon-darwin-styling td:nth-child(${headColumn}),
        .agon-darwin-styling th:nth-child(${headColumn}) {
          border-left: 1px solid #000000 !important;
        }
      `;
    },
    hide_chemical_row_titles: true,
  },
  [Template.AGON_SA_STYLING]: {
    analyte_title: 'Sample',
    unit_title: 'Units',
    chemical_fields: {},
    footer_colspan: 1,
    force_orientation: 'horizontal',
    display_scenario_on_top: true,
    display_on_top_gap: 1,
    getDynamicStyling: (sampleColumn: number) => {
      const headColumn = sampleColumn + 1;

      return `
        .agon-sa-styling thead tr.heading-row th:nth-child(n + ${headColumn}) {
          writing-mode: vertical-rl;
          transform: rotate(180deg);
          overflow: hidden;
          height: 225px;
          vertical-align: sub;
        }

        .agon-sa-styling .heading-sub-row:not(.eql_result_column) th:nth-child(-n + ${sampleColumn}),
        .agon-sa-styling thead .heading-row th:nth-child(-n + ${sampleColumn}) {
          border: none;
          width: 170px !important;
        }

        .agon-sa-styling thead .eql_result_column th:nth-child(-n + ${sampleColumn}) {
          border-top: 1px solid #000000 !important;
        }

        .agon-sa-styling thead .heading-sub-row th.chemical-group-header:nth-child(n + ${headColumn}) {
          border-top: 1px solid #000000;
          border-bottom: 1px solid #000000;
        }

        .agon-sa-styling.exporting tr.heading-row th:nth-child(n + ${headColumn}) {
          height: unset;
        }

        .agon-sa-styling td:nth-child(${headColumn}),
        .agon-sa-styling th:nth-child(${headColumn}) {
          border-left: 1px solid #000000 !important;
        }
      `;
    },
    hide_chemical_row_titles: true,
  },
  [Template.SENVERSA_STYLING]: {
    analyte_title: 'Location Code',
    unit_title: 'Units',
    chemical_fields: {
      [ChemicalFieldKey.UNIT_FIELD]: 'Unit',
    },
    footer_colspan: 1,
    force_orientation: 'vertical',
    display_scenario_on_top: true,
    display_header_under_subheading: true,
  },
  [Template.SLR_BULK_TEMPLATE]: {
    analyte_title: 'Field ID',
    unit_title: 'Units',
    footer_colspan: 1,
    force_orientation: 'horizontal',
    getDynamicStyling: (sampleColumn: number) => {
      const headColumn = sampleColumn + 1;

      return `
        .slr-bulk-styling thead tr.heading-row th:nth-child(n + ${headColumn}) {
          writing-mode: vertical-rl;
          transform: rotate(180deg);
          overflow: hidden;
          height: 225px;
          vertical-align: sub;
        }

        .slr-bulk-styling thead .heading-sub-row:not(.eql_result_column) th:nth-child(-n + ${sampleColumn}),
        .slr-bulk-styling thead .heading-row th:nth-child(-n + ${sampleColumn}) {
          border: none;
          width: 170px !important;
        }

        .slr-bulk-styling thead .eql_result_column th:nth-child(-n + ${sampleColumn}) {
          border-top: 1px solid #000000 !important;
        }

        .slr-bulk-styling thead .heading-sub-row th.chemical-group-header:nth-child(n + ${headColumn}) {
          border-top: 1px solid #000000;
          border-bottom: 1px solid #000000;
        }

        .slr-bulk-styling.exporting tr.heading-row th:nth-child(n + ${headColumn}) {
          height: unset;
        }

        .slr-bulk-styling td:nth-child(${headColumn}),
        .slr-bulk-styling th:nth-child(${headColumn}) {
          border-left: 1px solid #000000 !important;
        }
      `;
    },
    display_scenario_on_top: true,
    display_on_top_gap: 1,
    hide_depth_in_table: true,
    show_depth_in_heading: true,
  },
  [Template.HELIA_STYLING]: {
    analyte_title: 'Sample Code',
    unit_title: 'Units',
    chemical_fields: {
      [ChemicalFieldKey.EQL_FIELD]: 'LOR',
    },
    footer_colspan: 1,
    force_orientation: 'horizontal',
    getDynamicStyling: (sampleColumn: number) => {
      const headColumn = sampleColumn + 1;

      return `
        .helia-styling thead tr.heading-row th:nth-child(n + ${headColumn}) {
          writing-mode: vertical-rl;
          transform: rotate(180deg);
          overflow: hidden;
          height: 225px;
          border-bottom: 2px solid #000000;
          vertical-align: sub;
        }

        .helia-styling thead .heading-sub-row:not(.has-full-colspan,.chemical-group-row) th:nth-child(-n + ${sampleColumn}) {
          border: none;
          width: 170px !important;
        }
        
        .helia-styling thead .heading-sub-row.chemical-group-row th:nth-child(-n + ${sampleColumn}) {
          border: none;
          width: 160px
        }

        .helia-styling thead .heading-row th:nth-child(-n + ${sampleColumn}) {
          border: none;
          border-bottom: 2px solid #000000 !important;
          border-top: 2px solid #000000;
          text-align: right !important;
        }

        .helia-styling thead .heading-sub-row th.chemical-group-header:nth-child(n + ${headColumn}) {
          border: none;
          border-top: 2px solid #000000;
          border-bottom: 2px solid #000000;
        }

        .helia-styling.exporting tr.heading-row th:nth-child(n + ${headColumn}) {
          height: unset;
        }

        .helia-styling td:nth-child(${headColumn}),
        .helia-styling th:nth-child(${headColumn}) {
          border-left: 2px solid #000000 !important;
        }

        .helia-styling tr.has-full-colspan th:nth-child(${headColumn}) {
          border-left: none !important;
        }

        .helia-styling tr.has-full-colspan .stick-column-left {
          border-right: 2px solid #000000 !important;
          border-left: none !important;
          border-top: none !important;
          border-left: 2px solid #000000 !important;
        }

        .helia-styling tr.rpd_column th {
          border-bottom: 2px solid #000000 !important;
        }
      `;
    },
    display_scenario_on_top: true,
    display_on_top_gap: 1,
  },
  [Template.ARC_STYLING]: {
    analyte_title: 'Field ID',
    unit_title: 'Units',
    footer_colspan: 1,
    force_orientation: 'horizontal',
    getDynamicStyling: (sampleColumn: number) => {
      const headColumn = sampleColumn + 1;

      return `
        .arc-styling thead tr.heading-row th:nth-child(n + ${headColumn}) {
          writing-mode: vertical-rl;
          transform: rotate(180deg);
          overflow: hidden;
          height: 225px;    
          vertical-align: sub;
        }

        .arc-styling thead .heading-sub-row th:nth-child(-n + ${sampleColumn}),
        .arc-styling thead .heading-row th:nth-child(-n + ${sampleColumn}) {
          border: none;
          width: 170px !important;
        }

        .arc-styling thead .heading-sub-row th.chemical-group-header:nth-child(n + ${headColumn}) {
          border-top: 1px double #000000;
          border-bottom: 1px solid #000000;
        }

        .arc-styling.exporting tr.heading-row th:nth-child(n + ${headColumn}) {
          height: unset;
        }

        .arc-styling td:nth-child(${headColumn}),
        .arc-styling th:nth-child(${headColumn}) {
          border-left: 1px double #000000 !important;
        }
      `;
    },
    display_scenario_on_top: true,
    display_on_top_gap: 1,
    hide_chemical_row_titles: true,
  },
};

export default function useTableDesign(company_design: string) {
  const design = DESIGNS[company_design] || DESIGNS[Template.DEFAULT_TEMPLATE];

  let dynamicStyleElement;

  const getDesignKey = (key: string) => {
    if (!design) {
      return null;
    }
    return design[key] || null;
  };

  const getChemicalFieldTitle = (key: number) => {
    if (!design?.chemical_fields) return null;
    return design.chemical_fields[key];
  };

  const getSampleFieldTitle = (key: string) => {
    if (!design?.sample_fields) return null;
    return design.sample_fields[key];
  };

  const getDynamicStyling = (sampleCount: number, chemicalCount: number) => {
    return design?.getDynamicStyling
      ? design?.getDynamicStyling(sampleCount, chemicalCount)
      : null;
  };

  const applyDynamicStyling = (sampleCount: number, chemicalCount: number) => {
    const dynamicStyling = getDynamicStyling(sampleCount, chemicalCount);

    if (dynamicStyling) {
      if (dynamicStyleElement) {
        dynamicStyleElement.remove();
        dynamicStyleElement = null;
      }
      dynamicStyleElement = document.createElement('style');
      dynamicStyleElement.innerHTML = dynamicStyling;
      document.head.appendChild(dynamicStyleElement);
    }
  };

  return {
    design,
    getDesignKey,
    getChemicalFieldTitle,
    getSampleFieldTitle,
    getDynamicStyling,
    applyDynamicStyling,
  };
}
