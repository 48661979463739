import * as bl from './business-logic';

const path = '/figure8';

class Api {
  static getPath() {
    return path;
  }

  /**
   * Layers
   */
  static getListOfLayers(data) {
    return window.axios.get(`${Api.getPath()}/layer/list`, {
      params: data,
    });
  }

  static modifyLayerOrdering(data) {
    return window.axios.post(`${path}/layer/modify-ordering`, data);
  }

  static modifyLayer(data) {
    return window.axios.post(`${path}/layer/modify`, data);
  }

  static modifyMultipleLayers(data) {
    return window.axios.post(`${path}/layer/modify-multiple`, data);
  }

  static deleteLayer(data) {
    return window.axios.delete(`${path}/layer/delete`, {
      params: data,
    });
  }

  static getSampleRestrictions(sampleId) {
    return window.axios.get(`${path}/sample/${sampleId}/restrictions`);
  }

  static createSample(data) {
    return window.axios.post(`${path}/sample/create`, data);
  }

  static modifySample(data) {
    return window.axios.post(`${path}/sample/modify`, data);
  }

  static deleteSampleById({ id, forced = false }) {
    return window.axios.delete(`${path}/sample/delete/${id}?forced=${forced}`);
  }

  static importBulkSamples(data) {
    return window.axios.post(`${path}/sample/bulk-import`, data);
  }

  /**
   * Figures
   */
  /**
   * @deprecated Use `useFigureStore.fetchProjectFigures` instead.
   */
  static getListOfFigures(data) {
    return window.axios.get(`${Api.getPath()}/figure/list`, {
      params: data,
    });
  }

  static modifyFigure(data) {
    return window.axios.post(`${path}/figure/modify`, data);
  }

  static modifyFigureColumn(data) {
    return window.axios.post(`${path}/figure/modify-column`, data);
  }

  static modifyFigureBasemapImage({ data, progressUpdater }) {
    return window.axios.post(`${path}/figure/modify-basemap-image`, data, {
      onUploadProgress: (progressEvent) =>
        progressUpdater(
          parseInt(
            Math.round((progressEvent.loaded * 100) / progressEvent.total)
          )
        ),
    });
  }

  static deleteFigure(figure_id) {
    return window.axios.delete(`${path}/figure/delete/${figure_id}`);
  }

  // Doesn't edit the ProjectFigureLayer, instead edits the FigureLayer
  static modifyFigureLayer(data) {
    return window.axios.post(`${path}/figure/layer/modify`, data);
  }

  static rememberUserLastFigure(data) {
    return window.axios.put(`${path}/figure/remember-last-figure`, data);
  }

  /**
   * Additional Data
   */
  static getListOfChemicals() {
    return window.axios.get(`${path}/data/chemicals`);
  }

  static getListOfChemicalResults(data) {
    return window.axios.get(`${path}/data/chemical-results`, { params: data });
  }

  static getListOfScenarioStyles() {
    return window.axios.get(`/dashboard/scenario/get-styling`);
  }

  static getListOfExceedances() {
    return window.axios.get(`${path}/data/exceedances`);
  }

  static getListOfRecommendedServices(data) {
    return window.axios.get(`${path}/services/list`, {
      params: data,
    });
  }

  static searchRecommendedServices(data) {
    return window.axios.get(`${path}/services/search`, {
      params: data,
    });
  }

  static saveRecommendedService(data) {
    let { should_use_cors_proxy, axis_orientation } = data;
    should_use_cors_proxy = should_use_cors_proxy ?? false;
    axis_orientation =
      axis_orientation ?? bl.projection.AXIS_ORIENTATION_ENU.value;
    return window.axios.post(`${path}/services/modify`, {
      ...data,
      should_use_cors_proxy,
      axis_orientation,
    });
  }

  static deleteRecommendedService(id) {
    return window.axios.delete(`${path}/services/delete/${id}`);
  }

  static async loadGatherApps() {
    const {
      data: { tabs },
    } = await axios.get('/gather/tabs');
    const reqs = tabs.map((t) => {
      return axios.get(`/gather/template/${t.id}`, {
        params: {
          filters: {
            fieldFilterType: 'all-fields',
          },
        },
      });
    });
    const results = await Promise.allSettled(reqs);
    const failureIndex = results.findIndex((r) => r.status === 'rejected');
    if (failureIndex !== -1) {
      throw `Failed to load gather app ${tabs[failureIndex].id}.`;
    }
    return results.map((r) => r.value.data.template);
  }

  static getLinkedApps(params) {
    return window.axios.get(`/gather/linked-apps`, {
      params,
    });
  }

  static createSpatialBookmark(value) {
    return window.axios.post(`${path}/spatial-bookmark`, value);
  }

  static getSpatialBookmarks() {
    return window.axios.get(`${path}/spatial-bookmarks`);
  }

  static updateSpatialBookmark(value) {
    const { id } = value;
    delete value.id;
    return window.axios.put(`${path}/spatial-bookmarks/${id}`, value);
  }

  static deleteSpatialBookmark(id) {
    return window.axios.delete(`${path}/spatial-bookmarks/${id}`);
  }
}

export default Api;
